import React from 'react'
import {withRouter} from 'react-router'
import ReactDropzone from 'react-dropzone';
import $ from 'jquery'

import CmsHelper from '../../../helpers/Cms'

class Window extends React.Component {
  constructor(props) {
    super(props)

    this.title = React.createRef()

    this.state = {
      items: [],
      tab: 'media-library'
    }
  }

  componentDidMount() {
    this.getItems()
  }

  async getItems() {
    var response = await CmsHelper.get({
      type: 'attachments',
      sort: {
        _created: -1
      }
    })
    this.setState({
      items: response
    })
  }

  getInputValue(name) {
    return this[name].current.value
  }

  handleAddWbt(e) {
    e.preventDefault()
    /*fetch(
      'http://localhost:1337/wbts',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          title: this.getInputValue('title'),
          content: {
            modules: []
          }
        })
      }
    ).then(res => res.json()).then(
      (result) => {
        this.props.onCloseModal()
        this.props.history.push('/editor/' + result.id);
      }
    )*/
  }

  handleChangeField(e) {
    this.uploadFiles(this.inputElement.files)
  }

  handleFilesDropClick() {
    this.inputElement.click()
  }

  handleDragOver() {
    this.setState({
      dragOver: true
    })
  }

  handleDragLeave(files) {
    this.setState({
      dragOver: false
    })
  }

  onDrop(files) {
    this.uploadFiles(files)
    this.setState({
      dragOver: false
    })
  }

  async uploadFiles(files) {
    this.setState({
      isUploading: true
    })
    var fromData = new FormData()
    for(var i = 0; i < files.length; i++) {
      var fromData = new FormData()
      fromData.append('files[0]', files[i])
      var response = await fetch('https://perspektivenschmiede.fp-eauthor.fp-server.com/cms/api/cockpit/addAssets?token=1d40fd932ae1f0b9a197e1e7f2cdac', {
        method: 'POST',
        body: fromData
      }).then(res => res.json())
      await fetch(
        'https://perspektivenschmiede.fp-eauthor.fp-server.com/cms/api/collections/save/attachments',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            data: {
              title: response.uploaded[0],
              file: response.assets[0]
            }
          })
        }
      )
    }
    this.setState({
      isUploading: false,
      tab: 'media-library'
    })
    this.getItems()
  }

  handleOnClick(id, e) {
    this.props.onSelectFile(id)
  }

  handleTab(tab) {
    this.setState({
      tab: tab
    })
  }

  render() {
    const {
      items
    } = this.state

    var filter = ''
    if(this.props.modalArgs.filter !== undefined) {
      filter = this.props.modalArgs.filter
    }

    return(
      <div className="modal-window media-library">
        <div className="headline">Mediathek</div>
        <div className="filters">
          <div className="filters-tabs">
            <button
              className={'button' + (this.state.tab == 'media-library' ? ' active' : '')}
              onClick={this.handleTab.bind(this, 'media-library')}
            >
              <span className="label">Mediathek</span>
            </button>
            <button
              className={'button' + (this.state.tab == 'upload' ? ' active' : '')}
              onClick={this.handleTab.bind(this, 'upload')}
            >
              <span className="label">Datei hochladen</span>
            </button>
          </div>
        </div>
        {
          this.state.tab === 'media-library' ?
            <div className="main">
              <div className="attachments">
                {
                  items.map((item, index) => {
                    var ext = item.file.mime
                    var url = CmsHelper.getImageUrl({
                      file: item.file.path,
                      width: 320,
                      height: 320
                    })
                    if((filter === '' && item.file.mime !== 'audio/mpeg' && item.file.mime !== 'audio/x-wav' && item.file.mime !== 'video/mp4') || (filter === 'audio' && (item.file.mime === 'audio/mpeg' || item.file.mime === 'audio/x-wav')) || (filter === 'video' && item.file.mime === 'video/mp4') || (filter === 'image+video' && (item.file.mime === 'video/mp4' || (item.file.mime !== 'audio/mpeg' && item.file.mime !== 'audio/x-wav')))) {
                      return(
                        <button
                          key={item._id}
                          value={item._id}
                          className={'attachment' + (ext === 'image/svg+xml' ? ' svg' : '')}
                          style={{backgroundImage: 'url(' + url + ')'}}
                          onClick={this.handleOnClick.bind(this, item._id)}
                        >
                          {
                            ext === 'audio/mpeg' || ext === 'audio/x-wav' ?
                              <div className="audio">
                                <div className="icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="34.788" height="31.63" viewBox="0 0 34.788 31.63">
                                    <path id="Pfad_209" data-name="Pfad 209" d="M15.161,29.552a1.462,1.462,0,0,1-.963-.369h0l-6.468-6.83H1.475A1.475,1.475,0,0,1,0,20.831V12.191a1.475,1.475,0,0,1,1.475-1.475H7.7l6.454-6.9A1.482,1.482,0,0,1,16.643,4.9V28.118a1.462,1.462,0,0,1-.867,1.366,1.489,1.489,0,0,1-.615.068Zm-.41-.977a.683.683,0,0,0,1.072-.505V4.849a.683.683,0,0,0-1.093-.492L8.059,11.536H1.475a.683.683,0,0,0-.683.683v8.612a.683.683,0,0,0,.683.683H8.059Z" transform="translate(0.001 -1.084)" />
                                    <path d="M28.547,25.742a.451.451,0,0,0,.328-.048,6.051,6.051,0,0,0,.983-.813,5.648,5.648,0,0,0,0-7.977,5.511,5.511,0,0,0-1.045-.826.4.4,0,0,0-.225-.068h-.1a.4.4,0,0,0-.266.2.41.41,0,0,0-.055.321.423.423,0,0,0,.2.273,4.8,4.8,0,0,1,.881,7.472,5.136,5.136,0,0,1-.826.683.4.4,0,0,0-.191.266.423.423,0,0,0,.055.328A.444.444,0,0,0,28.547,25.742Z" transform="translate(-8.925 -5.074)" />
                                    <path d="M38.7,18.375A11.692,11.692,0,0,0,32.435,7.994a.4.4,0,0,0-.2,0h-.13a.458.458,0,0,0-.26.219.43.43,0,0,0,.184.581,10.818,10.818,0,0,1,2.636,17.224,10.641,10.641,0,0,1-2.616,1.933.41.41,0,0,0-.212.253.423.423,0,0,0,.034.328.41.41,0,0,0,.253.212.451.451,0,0,0,.328,0h0a11.863,11.863,0,0,0,2.827-2.083A11.61,11.61,0,0,0,38.7,18.375Z" transform="translate(-10.081 -2.529)" />
                                    <path d="M36.793,31.559A17.846,17.846,0,0,0,36.69.047.4.4,0,0,0,36.458,0a.437.437,0,0,0-.382.232.423.423,0,0,0-.034.328.41.41,0,0,0,.212.253,17.17,17.17,0,0,1,4.1,3.032,16.978,16.978,0,0,1-3.975,26.95.444.444,0,0,0-.212.26.423.423,0,0,0,0,.328.437.437,0,0,0,.628.178Z" transform="translate(-11.42 0.003)" />
                                  </svg>
                                </div>
                                <div className="label">{item.title}</div>
                              </div>
                            :
                              null
                          }
                          {
                            ext === 'video/mp4' ?
                              <div className="video">
                                <div className="label">{item.title}</div>
                              </div>
                            :
                              null
                          }
                        </button>
                      )
                    } else {
                      return null
                    }
                  })
                }
              </div>
            </div>
          :
            ''
        }
        {
          this.state.tab === 'upload' ?
            <div className="main">
              <ReactDropzone
                onDragOver={this.handleDragOver.bind(this)}
                onDragLeave={this.handleDragLeave.bind(this)}
                onDrop={this.onDrop.bind(this)}
              >
                {({getRootProps}) => (
                  <div
                    {...getRootProps()}
                  >
                    <div
                      className={'files-drop' + (this.state.dragOver ? ' drag-over' : '') + (this.state.isUploading ? ' uploading' : '')}
                      onClick={this.handleFilesDropClick.bind(this)}
                    >
                      <div className="text">
                        Dateien hier ablegen<br />
                        oder hier klicken um dateien auszuwählen
                      </div>
                      <div className="loading-bar"></div>
                      <form
                        id="upload-form"
                        className="form image-upload"
                        ref={input => this.form = input}
                        method="post"
                        encType="multipart/form-data"
                      >
                        <input
                          ref={input => this.inputElement = input}
                          type="file"
                          name="files[]"
                          multiple
                          onChange={this.handleChangeField.bind(this)}
                        />
                      </form>
                    </div>
                  </div>
                )}
              </ReactDropzone>
            </div>
          :
            ''
        }
        <button
          className="close-modal"
          onClick={this.props.onCloseModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
            <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
          </svg>
        </button>
      </div>
    )
  }
}

export default withRouter(Window)
