import React, {useState} from 'react'
import {Link, Redirect} from 'react-router-dom';
import { setUserSession } from '../../../Utils/Common';

function Login(props) {
  const [loading, setLoading] = useState(false);
  const email = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);

  // handle button click of login form
  const handleLogin = () => {
    setError(null)
    setLoading(true)
    checkLogin()
  }

  async function checkLogin() {
    var response = await fetch('https://perspektivenschmiede.fp-eauthor.fp-server.com/cms/api/collections/get/users?token=1d40fd932ae1f0b9a197e1e7f2cdac', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: {
          mail: email.value.toLowerCase()
        },
      })
    }).then(res => res.json())
    if(response.entries.length > 0) {
      const entry = response.entries[0]
      if(entry.password == password.value) {
        const rand = () => Math.random().toString(36).substr(2, 8)
        var token = rand() + rand() + rand() + rand()
        setUserSession(token, entry._id, email.value.toLowerCase(), entry.first_name, entry.last_name, entry.image.path)
        await fetch(
          'https://perspektivenschmiede.fp-eauthor.fp-server.com/cms/api/collections/save/tokens?token=1d40fd932ae1f0b9a197e1e7f2cdac',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              data: {
                mail: email.value.toLowerCase(),
                token: token
              }
            })
          }
        )
        props.history.push('/')
      } else {
        setError('Bitte überprüfen Sie Ihre Eingaben.');
      }
    } else {
      setError('Bitte überprüfen Sie Ihre Eingaben.');
    }
    setLoading(false)
  }

  /*response = await fetch(this.getEndpoint() + '/api/collections/get/' + args.type, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: {
        _id: args.id
      },
    })
  }).then(res => res.json())*/

  return(
    <div className="login">
      <div className="background"></div>
      <div className="login-container">
        <div className="headline">Welcome back!</div>
        {error && <div className="login-error">{error}</div>}
        <form className="form" onSubmit={e => e.preventDefault()}>
          <input className="light" type="email" placeholder="E-Mail Adresse" {...email} />
          <input className="light" type="password" placeholder="Passwort" {...password} />
          {/* <button className="button" type="submit">Login</button> */}
          <button className="button highlighted round" type="submit" onClick={handleLogin} disabled={loading}><div className="label">{loading ? 'Login...' : 'Login'}</div></button>
        </form>
      </div>
    </div>
  )
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login
